<template>
	<div class="content-body">
		<div v-if="!isFilmSingle">
			<b-row class="mt-2">
				<b-col>
					<b-form-select v-model="menuSelected" @change="handleChangeMenuSelected">
						<b-form-select-option :value="null">{{ FormMSG(43, 'All') }}</b-form-select-option>
						<b-form-select-option v-for="(menu, index) in menus" :key="index" :value="menu.value">
							{{ menu.text }}
						</b-form-select-option>
					</b-form-select>
				</b-col>
			</b-row>
			<b-row v-if="listTsDays.length > 0" class="mt-2">
				<b-col>
					<p style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">* {{ FormMSG(38, 'Select the Workdays you want to send for Validation.') }}</p>
				</b-col>
			</b-row>
			<b-row v-if="listTsDays.length === 0" class="mt-4">
				<b-col class="text-center">
					<p>
						{{ FormMSG(39, 'No timesheets found') }}
					</p>
				</b-col>
			</b-row>
		</div>
		<div v-else>
			<b-row v-if="listTsDays.length === 0" class="mt-4">
				<b-col class="text-center">
					<p>
						{{ FormMSG(39, 'No timesheets found') }}
					</p>
				</b-col>
			</b-row>
		</div>
		<b-row class="mt-2" style="margin-bottom: 100px">
			<b-col>
				<div
					v-for="(tsDay, i) in listTsDays"
					:key="i"
					style="border-radius: 8px; background-color: #f8f9fb"
					:style="`margin-top: ${i > 0 ? '15px' : '0'}; border: ${tsDay.isSelected ? '1px solid #00A09C' : 'none'}`"
				>
					<div style="padding: 16px">
						<div style="display: flex">
							<div v-if="tsDay.validated === 0 && tsDay.isInContract" style="width: 10%">
								<b-form-checkbox
									v-model="tsDay.isSelected"
									:value="true"
									:unchecked-value="false"
									@change="handleChangeTsDaySelected($event, tsDay)"
									size="lg"
								/>
							</div>
							<div :style="`width: ${tsDay.validated === 0 ? '90%' : '100%'}`">
								<b-row>
									<b-col cols="6">
										<div class="fw-400" style="color: #06263ed6; font-size: 13px; margin-top: 4px">
											{{ getDate(tsDay.date) }}
										</div>
									</b-col>
									<b-col cols="6">
										<div class="wrap-status d-flex justify-content-end" v-if="!isFilmSingle">
											<div :class="`status ${classStatus(tsDay.validated)}`" style="font-size: 0.795rem">
												{{ tsDay.validatedStatus }}
											</div>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col style="margin-top: 4px">
										<div class="fw-600 m-0" style="font-size: 16px">
											{{ getCurrency(tsDay.salary) }}
										</div>
									</b-col>
								</b-row>
								<div class="d-flex flex-row justify-content-start align-items-center pt-2">
									<!-- <b-col> -->
									<div class="info-time-co2">
										<Clock10 color="#06263ED6" :size="16" />
										{{ getHhString(tsDay.hhmm) }}
									</div>
									<div class="info-time-co2 ml-2">
										<Sprout color="#00A09C" :size="16" />
										{{ getCO2(tsDay.kgCoTwo) }}&nbsp;&nbsp;CO<sub>2</sub>
									</div>
									<!-- </b-col> -->
								</div>
							</div>
						</div>
					</div>
					<b-collapse :id="`collapse-${tsDay.id}`">
						<div style="padding-left: 16px !important; padding-right: 16px !important">
							<CardListBuilder
								style-mode="table"
								:items="changeToArray(tsDay)"
								:fields="tsfieldsMobile"
								:collapsed-fields="tsfieldsCollapseMobile"
								:toggle-filter-options="tsfieldsFilterOptions"
								:hideStatus="true"
								:showFilter="false"
								hide-filter-options
								styleMode="list"
							>
								<template
									slot="totPerDiem"
									slot-scope="data"
									v-if="showLunchPerDiem(data.item) || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()"
								>
									<div class="d-flex justify-content-end align-items-center">
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.left.click
											:title="tooltipContentPerIdem(data.item)"
											v-if="data.item.totPerDiem > 0"
										>
											<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
										</button>
										<div style="margin-top: 2px">{{ getCurrency(data.item.totPerDiem) }}</div>
									</div>
								</template>
								<template slot="totAllowances" slot-scope="data">
									<div class="d-flex justify-content-end align-items-center">
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.left.click
											:title="tooltipContentAllowance(data.item)"
											v-if="data.item.totAllowances > 0"
										>
											<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
										</button>
										<div style="margin-top: 2px">{{ getCurrency(data.item.totAllowances) }}</div>
									</div>
								</template>
							</CardListBuilder>
						</div>
					</b-collapse>
					<div class="mt-1" style="padding: 10px 16px; border-top: 2px solid white">
						<b-row>
							<b-col cols="5">
								<button
									class="btn-transparent color-blue fsz-13 flex-row justify-content-end align-items-center"
									v-b-toggle="`collapse-${tsDay.id}`"
									@click="tsDay.showDetails = !tsDay.showDetails"
								>
									<div v-if="!tsDay.showDetails" class="fsz-13">
										{{ FormMSG(33, 'Show details') }}
										<ChevronDown color="#225CBD" :size="15" />
									</div>
									<div v-else class="fsz-13">
										{{ FormMSG(34, 'Hide details') }}
										<ChevronUp color="#225CBD" :size="15" />
									</div>
								</button>
							</b-col>
							<b-col cols="7">
								<div class="d-flex justify-content-end align-items-center">
									<div v-if="tsDay.validated !== 0 && tsDay.validated !== 16 && tsDay.validated !== 4 && !isFilmSingle" class="">
										<button
											class="btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center"
											@click="handleClickEditTsDay(tsDay)"
										>
											<div style="margin-bottom: 2px; margin-right: 4px">
												<Eye color="#06263ED6" :size="14" />
											</div>
											<div>{{ FormMSG(85, 'View') }}</div>
										</button>
									</div>
									<div class="d-flex justify-content-end align-items-center" v-else>
										<button
											class="btn-transparent fsz-13 mr-2 d-flex flex-row justify-content-end align-items-center"
											@click="handleClickEditTsDay(tsDay)"
										>
											<div style="margin-bottom: 2px; margin-right: 4px">
												<Edit color="#06263ED6" :size="14" />
											</div>
											<div>{{ FormMSG(32, 'Edit') }}</div>
										</button>
										<button
											class="btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center"
											@click="handleClickDeleteTsDay(tsDay)"
										>
											<div style="margin-bottom: 2px; margin-right: 4px">
												<Trash2 color="#06263ED6" :size="14" />
											</div>
											<div>{{ FormMSG(16, 'Delete') }}</div>
										</button>
									</div>
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row v-if="!isFilmSingle && hasNotSubmitted" class="footer-fixed" style="z-index: 50">
			<b-col cols="12" class="mt-2">
				<b-button
					variant="primary"
					block
					:disabled="!tsDaysSelected.length > 0"
					style="font-size: 14px; font-weight: 700"
					@click="handleClickSendToValidation"
				>
					{{ FormMSG(45, 'Send to validation') }}
				</b-button>
			</b-col>
			<b-col cols="12" class="mt-2" style="font-size: 14px; font-weight: 700">
				<b-button variant="outline-primary" block :disabled="!hasNotSubmitted" @click="handleClickSelectAll">
					{{ FormMSG(41, 'Select all') }}
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import moment from 'moment';
import { rendCurrency, rendKgCo2 } from '~helpers';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { Clock10, Sprout, Edit, Trash2, ChevronDown, ChevronUp, Eye } from 'lucide-vue';
import { store } from '@/store';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { isNil } from '@/shared/utils';
import GlobalMixin from '@/mixins/global.mixin';
import { classStatus } from '@/shared/global-status';
import * as icons from 'lucide-vue';

export default {
	name: 'WorkDays',

	components: {
		Clock10,
		Sprout,
		Edit,
		Trash2,
		ChevronDown,
		ChevronUp,
		Eye
	},

	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],

	props: {
		tsDays: { type: Array, default: () => [], required: true }
	},

	data() {
		return {
			listTsDays: [],
			originalTsDays: [],
			tsDaysSelected: [],
			menuSelected: null,
			showGridDetails: true
		};
	},

	computed: {
		tsfields() {
			var flds = [];
			flds.push({
				key: 'isSelected',
				label: this.FormMSG(351, 'Selected'),
				formatter: (v) => v
			});
			var statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(50, 'Status'),
				sortable: true
			};
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(statusFld);
			}
			var fldsBase = [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					formatter: (v) => v.substring(8, 10),
					sortable: true
				},
				{
					key: 'strTime',
					label: this.FormMSG(52, 'Start'),
					// formatter: v => v.split('T')[1].substring(0, 5),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.strTime.split('T')[1].substring(0, 5);
						return root.strTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.strTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'endTime',
					label: this.FormMSG(53, 'End'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const strDay = new Date(root.strTime).getDay();
						const endDay = new Date(root.endTime).getDay();
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(54, 'Type'),
					formatter: (value, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails
							? value == 2
								? 'T'
								: value == 1
								? 'C'
								: 'F'
							: value == 2
							? 'T' + separator + rendCurrency(root.salaryBase)
							: value == 1
							? 'C' + separator + rendCurrency(root.salaryBase)
							: 'F' + separator + rendCurrency(root.salaryBase);
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(55, 'Hours'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						return !this.showGridDetails ? root.hhmm : root.hhmm;
					},
					sortable: false
				}
			];

			flds = flds.concat(fldsBase);
			//console.log("flds",flds)
			var ovtFld = {
				key: 'hhmmOvt',
				label: this.FormMSG(56, 'Ovt'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
				}
			};
			if (this.showOvt()) {
				flds.push(ovtFld);
			}

			var ovt1Fld = {
				key: 'hhmmOvt1',
				label: this.FormMSG(156, 'Ovt1'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
				}
			};
			if (this.showOvt1()) {
				flds.push(ovt1Fld);
			}
			var ovt2Fld = {
				key: 'hhmmOvt2',
				label: this.FormMSG(157, 'Ovt2'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
				}
			};
			if (this.showOvt2()) {
				flds.push(ovt2Fld);
			}

			var transportFld = {
				key: 'hhmmTransport',
				label: this.FormMSG(158, 'Transport'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
				}
			};
			if (this.showTransport()) {
				flds.push(transportFld);
			}

			var nightFld = {
				key: 'hhmmNight',
				label: this.FormMSG(57, 'Night'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
				}
			};
			if (this.showNightHours()) {
				flds.push(nightFld);
			}

			var tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(58, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			if (this.showTooEarlyHours()) {
				flds.push(tooEarlyHoursFld);
			}

			var lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(60, 'Lunch start'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(65, 'Lunch end'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				flds = flds.concat(lunchflds);
			}

			var flds1 = [
				{
					key: 'dayRate',
					label: this.FormMSG(59, 'Rate'),
					formatter: (value) => {
						return value + ' %';
					},
					sortable: false
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true
				},
				{
					key: 'salary',
					label: this.FormMSG(63, 'Salary'),
					formatter: (value) => rendCurrency(value),
					sortable: false
				},
				{
					key: 'rem',
					label: this.FormMSG(64, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			flds = flds.concat(flds1);

			return flds;
		},
		tsfieldsMobile() {
			let fields = [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					formatter: (v) => moment(v).format('DD/MM/YYYY'),
					sortable: true
				},
				{
					key: 'strTime',
					label: this.FormMSG(52, 'Start'),
					formatter: (value, key, item) => {
						if (isNil(item)) return '';
						if (item.strEndNotSpecified === false) {
							return value.split('T')[1].substring(0, 5);
						} else {
							return '--:--';
						}
					},
					sortable: false
				},
				{
					key: 'endTime',
					label: this.FormMSG(53, 'End'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						if (root.strEndNotSpecified === false) {
							const strDay = new Date(root.strTime).getDay();
							const endDay = new Date(root.endTime).getDay();
							const time = root.endTime.split('T')[1].substring(0, 5);
							return root.endTime.substring(8, 10) == root.strTime.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
						} else {
							return '--:--';
						}
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(54, 'Type'),
					formatter: (value, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails
							? value == 2
								? 'T'
								: value == 1
								? 'C'
								: 'F'
							: value == 2
							? 'T' + separator + rendCurrency(root.salaryBase)
							: value == 1
							? 'C' + separator + rendCurrency(root.salaryBase)
							: 'F' + separator + rendCurrency(root.salaryBase);
					},
					sortable: false
				},
				{
					key: 'hhmmOvt',
					label: this.FormMSG(169, 'Overtime'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
					}
				},
				{
					key: 'hhmmOvt1',
					label: this.FormMSG(170, 'Overtime 1'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
					}
				},
				{
					key: 'hhmmOvt2',
					label: this.FormMSG(171, 'Overtime 2'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
					}
				},
				{
					key: 'hhmmTransport',
					label: this.FormMSG(158, 'Transport'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
					}
				},
				{
					key: 'hhmmNight',
					label: this.FormMSG(57, 'Night'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
					}
				}
			];

			const tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(58, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			fields.push(tooEarlyHoursFld);

			const lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(60, 'Lunch start'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(65, 'Lunch end'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				fields = fields.concat(lunchflds);
			}

			const totPerDiem = {
				key: 'totPerDiem',
				label: this.FormMSG(368, 'Per Diem'),
				isSlot: true
			};
			fields.push(totPerDiem);

			const totalAllowance = {
				key: 'totAllowances',
				label: this.FormMSG(369, 'Allowances'),
				isSlot: true
			};
			fields.push(totalAllowance);

			fields.push({
				key: 'dayRate',
				label: this.FormMSG(59, 'Rate'),
				formatter: (value) => {
					return value + ' %';
				},
				sortable: false
			});

			return fields;
		},
		tsfieldsCollapseMobile() {
			const keys = ['salary', 'hhmm', 'kgCoTwo', 'rem', 'validatedStatus'];
			return this.tsfields.filter((t) => !keys.includes(t.key));
		},
		tsfieldsFilterOptions() {
			return this.isFilmSingle
				? {}
				: {
						key: 'validated',
						choices: [
							{
								value: 'all',
								text: this.FormMSG(8009777, 'All')
							},
							{
								value: 0,
								text: this.FormMSG(897977, 'Not Submitted')
							},
							{
								value: 8,
								text: this.FormMSG(8977, 'Validated')
							}
						]
				  };
		},
		menus() {
			return this.FormMenu(1008);
		},
		hasNotSubmitted() {
			let res = false;
			for (let i = 0; i < this.tsDays.length; i++) {
				if (this.tsDays[i].validated === 0) {
					res = true;
					break;
				}
			}
			return res;
		}
	},

	watch: {
		tsDays: {
			handler(val) {
				this.listTsDays = val;
				let result = [];
				for (let i = 0; i < val.length; i++) {
					result.push({
						id: val[i].id,
						date: val[i].date,
						strTime: val[i].strTime,
						endTime: val[i].endTime,
						leftHomeAt: val[i].leftHomeAt,
						isSelected: val[i].isSelected,
						showDetails: val[i].showDetails,
						returnedHomeAt: val[i].returnedHomeAt,
						totMin: val[i].totMin,
						lunchStrTime: val[i].lunchStrTime,
						lunchEndTime: val[i].lunchEndTime,
						lunch: val[i].lunch,
						hotel: val[i].hotel,
						stageDayUsed: val[i].stageDayUsed,
						dayType: val[i].dayType,
						validated: val[i].validated,
						kgCoTwo: val[i].kgCoTwo,
						validatedStatus: val[i].validatedStatus,
						hours: val[i].hours,
						minutes: val[i].minutes,
						hoursOvertime: val[i].hoursOvertime,
						minutesOvertime: val[i].minutesOvertime,
						hoursOvertime1: val[i].hoursOvertime1,
						minutesOvertime1: val[i].minutesOvertime1,
						hoursOvertime2: val[i].hoursOvertime2,
						minutesOvertime2: val[i].minutesOvertime2,
						hoursTransportTimePaid: val[i].hoursTransportTimePaid,
						minutesTransportTimePaid: val[i].minutesTransportTimePaid,
						hoursNight: val[i].hoursNight,
						minutesNight: val[i].minutesNight,
						hoursTooEarly: val[i].hoursTooEarly,
						minutesTooEarly: val[i].minutesTooEarly,
						salary: val[i].salary,
						hhmm: val[i].hhmm,
						hhmmOvt: val[i].hhmmOvt,
						hhmmOvt1: val[i].hhmmOvt1,
						hhmmOvt2: val[i].hhmmOvt2,
						hhmmTransport: val[i].hhmmTransport,
						hhmmNight: val[i].hhmmNight,
						hhmmTooEarly: val[i].hhmmTooEarly,
						comment: val[i].comment
					});
				}

				this.originalTsDays = result;
				this.menuSelected = null;
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		changeToArray(value) {
			let result = [];
			result.push(value);
			return result;
		},
		handleClickSelectAll() {
			for (let i = 0; i < this.listTsDays.length; i++) {
				if (this.listTsDays[i].validated === 0) {
					this.listTsDays[i].isSelected = true;
					this.tsDaysSelected.push(this.listTsDays[i]);
				}
			}
		},
		handleClickSendToValidation() {
			this.menuSelected = null;
			this.$emit('work-days:send-to-validation', {
				tsDaysSelected: this.tsDaysSelected
			});
			this.oldTsDaysSelected = this.tsDaysSelected;
			this.tsDaysSelected = [];
		},
		handleChangeTsDaySelected(payload, tsDay) {
			if (payload) {
				this.tsDaysSelected.push(tsDay);
			} else {
				this.tsDaysSelected = this.tsDaysSelected.filter((tsDaySelected) => tsDaySelected.id !== tsDay.id);
			}
		},
		handleChangeMenuSelected(payload) {
			if (payload === null) {
				this.listTsDays = this.originalTsDays;
			} else {
				this.listTsDays = this.originalTsDays.filter((tsDay) => tsDay.validated === payload);
			}
		},
		handleClickEditTsDay(tsDay) {
			const extractDate = !isNil(tsDay.date) && tsDay.date !== '' ? tsDay.date.split('T') : null;
			let dateId = '';
			if (extractDate && extractDate.length > 0) {
				dateId = tsDay.date.split('T')[0];
				if (this.$isIOSDevice()) {
					const date = new Date(dateId);
					const month = parseInt(date.getMonth()) + 1 < 10 ? '0' + (parseInt(date.getMonth()) + 1) : parseInt(date.getMonth());
					const day = parseInt(date.getDate()) < 10 ? '0' + parseInt(date.getDate()) : parseInt(date.getDate());
					dateId = date.getFullYear() + '-' + month + '-' + day;
				}
			} else {
				return;
			}
			const date = new Date(tsDay.date);

			this.$emit('work-days:edit-timesheet', {
				id: dateId,
				date: date
			});
		},
		handleClickDeleteTsDay(tsDay) {
			const action = () => {
				this.handleChangeTsDaySelected(false, tsDay);
				this.$emit('work-days:delete-timesheet', tsDay.id);
			};
			this.confirmModal(action, this.FormMSG(40, 'Are you sure ?'));
		},
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		getCO2(val) {
			return rendKgCo2(val);
		},
		getCurrency(val) {
			return rendCurrency(val);
		},
		getHhString(value) {
			let splitValue = value.split(':');
			if (parseInt(splitValue[0], 10) < 10) {
				splitValue[0] = '0' + parseInt(splitValue[0]);
			}
			if (parseInt(splitValue[1], 10) < 10) {
				splitValue[1] = '0' + parseInt(splitValue[1]);
			}
			return `${splitValue[0]}h ${splitValue[1]}`;
		},
		getDate(value) {
			const date = new Date(value);
			return moment(date).format('DD MMMM YYYY ');
		},

		tomorrow() {
			var today = new Date();
			var tmw = new Date(today.getTime() + 24 * 60 * 60 * 1000);
			return tmw;
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showTransport() {
			//console.log("in show transport:",store.getCurrentProjectConfig());
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showLunch() {
			return store.getCurrentProjectConfig().useLunchTime;
		},
		showLunchPerDiem(item) {
			return store.getCurrentProjectConfig().useLunchPerDiem && item.stageDayUsed === 0;
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		showDinnerPerDiem() {
			return store.getCurrentProjectConfig().useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.getCurrentProjectConfig().useAbroadPerDiem;
		},
		showStageDay() {
			return store.isPme() || this.isFilmSingle || !store.getCurrentProjectConfig().useStageDay ? false : true;
		},
		disableSendData() {
			for (var i in this.listTsDays) {
				if (this.listTsDays[i].validated == 0) {
					return false;
				}
			}
			return true;
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		tooltipContentPerIdem(item) {
			let content = [];

			if (this.showHotelPerDiem() && item.hotel && item.contract.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.contract.hotelPerDiem));
			}

			if (this.showLunchPerDiem(item) && item.lunch && item.contract.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.contract.lunchPerDiem));
			}

			if (this.showDinnerPerDiem() && item.useDinner && item.contract.dinnerPerDiem > 0) {
				content.push(this.FormMSG(212, 'Dinner: ') + this.getCurrency(item.contract.dinnerPerDiem));
			}

			if (this.showAbroadPerDiem() && item.useAbroad && item.contract.abroadPerDiem > 0) {
				content.push(this.FormMSG(211, 'Abroad: ') + this.getCurrency(item.contract.abroadPerDiem));
			}

			return content.join(', ');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') + '(' + this.rendAllowanceType(item.contract.phoneAllowanceRate) + '): ' + this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			return content.join(', ');
		},
		getLucideIcon(name) {
			return icons[name];
		}
	}
};
</script>

<style scoped></style>
